@import url(https://fonts.googleapis.com/css?family=Oswald:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,400i,500,600,600i,700,800,900);

body {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666;
  letter-spacing: .5px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    
    font-family: Oswald, sans-serif;
    
}

.carousel-caption .sbtn a {
  background: transparent;
  border: 1px solid transparent;
  color: #fff!important;
  border-radius: 10px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
a {
  color: #fff!important;
  text-decoration: none;
  background-color: transparent;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin: 0px!important;
}
.carousel-caption {
  padding: 20px 0px !important;
  top: 30%!important;
}
/* .slidersection {
  height: 200px!important;
} */
.carousel-caption h3 {
  font-size: 60px;
  margin-bottom: 30px;
  color:#fff;
}
.carousel-caption p {
  font-size: 20px;
  margin-bottom: 20px;
  color:#fff;
}
.carousel img {
  max-height: 600px;  /* change this to whatever you want */
  width: auto;
}

.carousel button.sbtn {
  padding: 10px 30px;
  background: #1f2d5b;
  border: 1px solid #1f2d5b;
  font-size: 16px;
}
.mr-auto.navbar-nav a {
  padding: 0px 15px!important;
}

.top-btn {
  padding: 24px 20px;
}
.top-btn button {
  padding: 8px 13px;
  background: #cd5219;
  border: #cd5219;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
  color: #fff;
  border-radius: .25rem;
}
.top-btn button:active  {
  padding: 8px 13px;
  background: #cd5219;
  border: #cd5219;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
  color: #fff;
  border-radius: .25rem;

}
.top-btn button:focus {
  padding: 8px 13px;
  background: #cd5219;
  border: #cd5219;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
  color: #fff;
  border-radius: .25rem;

}
.top-btn button:hover {
  padding: 8px 13px;
  background: #cd5219;
  border: #cd5219;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
  color: #fff;
  border-radius: .25rem;

}
.topbar .container {
  max-width: 100%;
  padding: 0px 20px;
}
.top-btn {
  border-right: 2px solid #ddd;
}
.section-block-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding: 80px 0 80px 0;
}
.heading-title {
  margin-bottom: 30px;
}
.text-center {
  text-align: center!important;
}
.heading-title .title {
  position: relative;
  padding-bottom: 20px;
}
.hps-tw-7 {
  font-weight: 700;
}
.heading-title .title:before {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  height: 4px;
  background: #003082;
  display: inline-block;
}
.heading-title p {
  padding: 0 88px;
  display: block;
  margin-bottom: 50px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: 500;
  font-size: 16px;
}
.mt-40 {
  margin-top: 40px;
}
.plane-box {
  margin: 0 auto;
  text-align: center;
}
.plane-box-item {
  position: relative;
  display: inline-block;
  width: 33.33%;
  margin-right: -3px;
  padding-right: 40px;
  padding-left: 40px;
}
.plane-box-decor {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 29px;
  height: 29px;
}
.plane-box-decor::before {
  background-color: #003082;
  position: absolute;
  top: 10px;
  left: 10px;
  display: block;
  width: 9px;
  height: 9px;
  content: '';
  border-radius: 50%;
  box-shadow: 0 0 0 5px rgba(0, 48, 130, .2);
}
.plane-box-decor::after {
  background-color: #003082;
  display: block;
  width: 1px;
  height: 15px;
  margin: 19px auto 0;
  content: '';
}
.plane-box-text {
  position: relative;
  display: inline-block;
}
.plane-box-text h3 {
  color: #bbb;
  font-size: 52px;
  font: italic 60px;
  margin: 15px 0;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  font-family: initial;
}
.plane-box-text h4 {
  color: #222;
  font-size: 28px;
  font-weight: 600;
  margin: 15px 0;
  letter-spacing: .6px;
}
.plane-box-text p {
  color: #585858;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
section.overview-block-ptb.bg-2 {
  padding: 70px 0px;
}
.overview-block-ptb {
  padding: 80px 0;
}
.small-title {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 15px;
  text-align: left;
}
.hps-tw-6 {
  font-weight: 600;
}
.overview-block-ptb p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 29px;
  text-align: left;
}
.small-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 4px;
  background: #003082;
  display: inline-block;
}

.hps-font-black {
  color: #222;
}
.hps-mt-15 {
  margin-top: 15px;
}
.hps-mb-20 {
  margin-bottom: 20px;
}
.hps-footer3 .hps-contact-box .hps-icon {
  float: left;
  text-align: center;
  margin-right: 15px;
  color: #003082;
}
.hps-footer3 .hps-contact-box .hps-icon i {
  font-size: 40px;
}
.hps-footer3 .hps-contact-box .hps-content {
  display: table-cell;
}
.hps-lead {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
.hps-font-black {
  color: #222;
}
.hps-tw-6 {
  font-weight: 600;
}
.hps-tw-6 {
  font-weight: 600;
}
.hps-footer3 .hps-contact-box .hps-icon i {
  font-size: 40px;
}
.footer-top .hps-content {
  text-align: left;
}
.footer-bottom {
  padding: 12px 0;
  background: #1f2d5b;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}
/* #content-inner-about:before {
  background: #0c2a48;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  z-index: 0;
  mix-blend-mode: multiply;
} */
/* #content-inner-about {
  -webkit-background-size: cover !important;
  -o-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-attachment: fixed !important;
  position: relative;
  padding: 50px 0;
  background: url(https://medwinmechanical.com/wp-content/uploads/2018/05/pexels-photo-290595.jpeg) no-repeat 0px 0px;
} */
.video-main-inner-content p {
  color: #fff;
  font-size: 24px;
  line-height: 40px;
}
.footer-top {
  padding: 70px 0px;
}
section#content-inner-about {
  background:#1f2d5b;
  padding: 25px 0px;
}
.hps-font-black.hps-mt-15 {
  text-align: left;
}
.section-block-bg.overview-block-ptb .heading-title.text-center p {
  text-align: center;
}
section.overview-block-ptb button {
  float: left;
}
/*mobile*/
@media only screen and (max-width: 479px)
{
  header {
    background: #fff!important;
  }
  .carousel-caption h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color:#fff;
  }
  .carousel-caption {
    padding: 20px 0px !important;
    top: 0%!important;
}
.carousel-caption p {
  font-size: 16px;
  margin-bottom: 20px;
  color:#fff;
  
}
.top-btn {
  display: none;
}
h2.title.hps-tw-7 {
  font-size: 25px;
}
.plane-box-text p {
  text-align: center;
}
h3.small-title.hps-tw-6.hps-mb-10 {
  margin: 40px 0px;
}
}

#banner-section {
  background-size: cover !important;
  /* background-position-y: 46%; */
  /* background-position: center; */
  position: relative !important;
  /* background-attachment: fixed !important; */
  padding: 80px 0;
  background: url(../src/assetes/images/banner.jpg);
}

#banner-section:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #1f2d5b;
  content: '';
  mix-blend-mode: multiply;
}

.banner-heading-text {
  color: #fff;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
  font-size: 36px;
}
section.welcome-section {
  padding: 70px 0px;
}
section.welcome-section p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 29px;
  text-align: left;
}
.topbar-left img {
  width: 80px;
  float: left;
}
.navbar {
  
  padding: 200px 10px;
}
.dropdown-menu.show a {
  font-size: 13px;
  padding: 5px 10px!important;
  line-height: 28px !important;
}
.footerlogo img {
  width: 100px;
}
.footerlogo {
  overflow: hidden;
  text-align: left;
}
.dropdown-item:focus, .dropdown-item:hover {
 
  background-color: #1f2d5b!important;
  
}
.dropdown-item:hover a{
  color:#fff!important;
}
.home-second-img img {
  width: 100%;
}
.about-service-detail-inner {
  display: flex;
  margin-bottom: 35px;
  border-right: 2px solid #1f2d5b;
  padding-right: 10px;
}

.about-service-detail-inner p {
  margin-bottom: 0px;
}
.about-service-detail-inner i {
  font-size: 20px;
  margin-right: 20px;
  padding: 11px 12px;
  color: #fff;
  border: 1px solid;
  border-radius: 50%;
  background: #1f2d5b;
}
.about-service-detail-inner span h4 {
  font-size: 22px;
  margin: 0 0 5px 0px;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
}
.about-service-detail-inner span p {
  font-size: 17px;
  color: #000;
  line-height: 27px;
  letter-spacing: 1px;
}
.about-service-detail-inner span {
  text-align: left;
}
section.about-service-section {
  padding: 70px 0px;
}
section.about-service-section {
  background: #eeeeee21;
}
.video-main-inner-content h4 {
  font-size: 36px;
  margin: 0 0 30px 0;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}
.video-main-inner-content a {
  font-size: 16px;
    padding: 10px 25px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 5px;
  transition: all 0.5s ease;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  display: inline-block;
}
section.contact.content-area {
  padding: 70px 0px;
}

.contact-info {
  text-align: left;
}
section.contact.content-area h3.heading {
  text-align: left;
}
.media i {
  margin-right: 15px;
  color: #003082;
  font-size: 40px;
}
section.contact.content-area button.btn.btn-md.button-theme {
  font-size: 16px;
  font-weight: 700;
  padding: 14px 20px 14px;
  margin-bottom: 4px;
  line-height: 1;
  display: inline-block;
  min-width: 8.5em;
  text-align: center;
  color: #fff!important;
  border: none;
  background-color: #1f2d5b;
  border-radius: 0;
  position: relative;
  float: left;
  text-align: all .4s linear 0s;
}
section.contact.content-area .media-body h5 {
  line-height: 18px;
  font-size: 18px;
}
section.contact.content-area .media-body p a {
  color: #000;
}
.sbtn a{
  background: #cd5219;
  border: 1px solid #cd5219;
  color: #fff!important;
  border-radius: 10px;
}
.mr-auto, .mx-auto {
  
  margin-right: 0px!important;
}
button.dbtn {
  font-size: 16px;
  font-weight: 700;
  padding: 14px 20px;
  margin-bottom: 4px;
  line-height: 1;
  display: inline-block;
  min-width: 115px;
  text-align: center;
  color: #fff!important;
  border: none;
  background-color: #1f2d5b;
  border-radius: 5px;
  position: relative;
  transition: all .4s linear 0s;
  float: left;
  margin-top: 20px;
  width: 150px;
}
.link iframe {
  width: 100%;
}
.link iframe {
  width: 100%;
  height: 200px;
}
.logo-sec .InfiniteCarousel {
  position: relative;
  width: 100%;
}
.logo-sec .InfiniteCarouselSlide img {
  width: 100%;
  padding: 15px;
}
.logo-sec ul.InfiniteCarouselDots {
  display: none;
}
.logo-sec  .InfiniteCarouselArrowPrev {
  left: 0px;
  right: auto;
  opacity: 0;

}
.logo-sec div#infinite-carousel:hover .InfiniteCarouselArrowPrev {
  opacity: 1;
}
section.logo-sec {
  background: #f6f6f6;
}
section.logo-sec {
  background: #f6f6f6;
  padding: 10px 0px;
}
.logo-sec .InfiniteCarouselArrowIcon {
  display: inline-block;
  padding: 6px;
  border: solid #1f2d5b;
  border-width: 0 3px 3px 0;
  font-size: 10px!Important;
}
.logo-sec .InfiniteCarouselArrowNext {
  left: auto;
  right: 0px;
  opacity: 0;
}
.logo-sec div#infinite-carousel:hover .InfiniteCarouselArrowNext {
  opacity: 1;
}
ul.hps-media-blog {
  float: left;
}
ul.hps-media-blog li {
  margin-right: 10px;
}
ul.hps-media-blog li {
  display: inline-block;
}
ul.hps-media-blog li i {
  padding: 10px;
  color: #1f2d5b;
  border: 1px solid #1f2d5b;
  border-radius: 50%;
  width: 38px;
  display: block;
  line-height: 15px;
  font-size: 20px;
}
ul.hps-media-blog li:hover i {
  background: #1f2d5b;
  color: #fff;
}
h4.flu {
  text-align: left;
  font-size: 20px;
  margin-bottom: 20px;
}
.media-body p a {
  color: #000!important;
}
.contact-person li.list-group-item img {
  width: 100px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.person-detail {
  text-align: left;
}
.contact-person {
  margin-top: 30px;
}
.contact-person .row.person-inner {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px 10px;
}
.contact-person li.list-group-item {
  border: none;
}
.person-detail {
  padding-left: 20px;
}
.person-detail span.name {
  font-weight: 700;
}
.team-list .item {
  width: 20%;
  height: 100%;
  padding: 30px 0px;
  text-align: center;
  display: inline-block;
}
.team-list .item:nth-child(1) {
  background: rgb(16, 31, 46);
}
.team-list .item i {
  vertical-align: middle;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.8);
}
.team-list .item p.number {
  font-size: 40px;
  padding: 0;
  font-weight: bold;
  
  line-height: 45px;
}
.team-list .item p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  margin: 0;
  padding: 10px;
  font-family: 'Open Sans';
}
.team-list .item span.bline {
  width: 60px;
  background: rgba(255, 255, 255, 0.8);
  height: 2px;
  display: block;
  margin: 0 auto;
}
.team-list .item p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  margin: 0;
  padding: 10px;
  font-family: 'Open Sans';
}

.team-list .item:nth-child(2) {
  background: rgb(18, 34, 51);
}
.team-list .item:nth-child(3) {
  background: rgb(21, 38, 56);
}
.team-list .item:nth-child(4) {
  background: rgb(23, 44, 66);
}

.team-list .item:nth-child(5) {
  background: rgb(18, 34, 51);
}
.team-list .item:nth-child(6) {
  background: rgb(23, 44, 66);
}
.team-list .item:nth-child(7) {
  background: rgb(18, 34, 51);
}
section.chitransh.team-sec .team-list .item {
  width: 14.28%;
  /* margin-left: 6px; */
}
.team-list .item img {
  width: 50px;
  /* border: 1px solid #ddd; */
  /* border-radius: 50%; */
  /* padding: 5px; */
}
.about-service-detail-inner ul li i {
  -webkit-transform: rotate(-90deg);
  margin-right: 10px;
  transform: rotate(-90deg);
  background: none;
  color: #1f2d5b;
  border: none;
  padding: 0px;
}
.about-service-detail-inner ul li {
  font-weight: 600;
  margin-bottom: 5px;
}
.slidersection .first-slider.carousel-item {
  background-image: url(../src/assetes/images/banner1.png);
  background-size: cover;
  padding: 0px 0px;
  height: 600px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.slidersection .second-slider.carousel-item {
  background-image: url(../src/assetes/images/new/slider2.jpg);
  background-size: cover;
  padding: 0px 0px;
  height: 600px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.slidersection .third-slider.carousel-item {
  background-image: url(../src/assetes/images/new/slide3.jpg);
  background-size: cover;
  padding: 0px 0px;
  height: 600px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.60);
  opacity: 1;
  display: block;
}
.slidersection .carousel-caption {
  position: relative!important;

  left: 0px!important;
}

.col-md-6.post-content p {
  text-align: left;
}
.service-block {
  margin: auto;
}
.teaser_content .governance-main-inner ul li {
  color: rgba(48, 48, 48, .8);
  text-align: left;
}
/*mobile*/
@media only screen and (max-width: 600px)
{
  section.chitransh.team-sec .team-list .item {
    width: 100%;
    /* margin-left: 6px; */
}
.team-list .item {
  width: 100%;
}
.about-service-detail {
  padding-top: 30px;
}
section.about-service-section {
  padding: 0px 0px;
}
section.manufacturing-sec {
  padding-left: 10px;
  padding-right: 10px;
}

}
a.chitranshlogo img {
  width: 100px;
  margin-left: 25px;
}