.man-content {
    text-align: center;
    
    margin: auto;
}

.man-content h2:before {
    content: "";
    position: absolute;
    top: 40px;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    height: 4px;
    background: #003082;
    display: inline-block;
    /* margin-bottom: 20px; */
}
.man-content h2 {
    margin-bottom: 28px;
}
.bg-sec{
    background: url(../../assetes/images/access.jpg);
    position: relative;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
/* section.bg-sec:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    
    background: rgba(255, 255, 255, .8);
} */
section.bg-sec .flex-column.nav.nav-pills {
    border-right: 4px solid #cd5219;
}
.bg-sec .nav-item {
    padding: 5px 0px;
    text-align: left;
}
.bg-sec .nav-item i {
    -webkit-transform: rotate(-90deg);
    margin-right: 10px;
    transform: rotate(-90deg);
}
.bg-sec .nav-pills .nav-link, .nav-pills .show>.nav-link {
    
    background-color: transparent;
    color: #1F2D5A!important;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    
}
.bg-sec .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #cd5219 !important;
}
.tab-content p {
    text-align: left;
}
.tab-content a {
    font-size: 16px;
    font-weight: 700;
    padding: 14px 20px 14px;
    margin-bottom: 4px;
    line-height: 1;
    display: inline-block;
    min-width: 8.5em;
    text-align: center;
    color: #fff!important;
    border: none;
    background-color: #1f2d5b;
    border-radius: 0;
    position: relative;
    transition: all .4s linear 0s;
    float: left;
}
section.bg-sec {
    padding: 50px 0px;
}
.bg-inner-sec {
    background: #fff;
    padding: 30px 0px;
}
.tab-content .governance-main-inner ul li {
    text-align: left;
}