section {
    padding: 70px 0px;
}
section.desgine-sec {
    background: #fff;
}
section.desgine-sec {
    text-align: left;
}
h2 {
    color: #1f2d5b;
    font-size: 30px;
    margin-bottom: 18px;
}
.governance-main-inner li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    margin-bottom: 15px;
}
.governance-main-inner li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    margin-bottom: 15px;
}
.governance-main-inner li:after {
    background: url(../../assetes/images/arrow-gold.png);
    position: absolute;
    content: '';
    left: 0;
    top: 6px;
    background-size: contain;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    -webkit-transition: all .3s ease-in 0s;
    -moz-transition: all .3s ease-in 0s;
    -ms-transition: all .3s ease-in 0s;
    -o-transition: all .3s ease-in 0s;
    transition: all .3s ease-in 0s;
}
section.manufacturing-sec {
    background: #f6f6f6;
    text-align: left;
}
.top-sec {
    text-align: center;
}
.governance-main-inner ul li a {
    color: #000!important;
}
.governance-main-inner li span {
    font-size: 16px;
    margin-bottom: 7px;
    line-height: 20px;
    font-weight: 600;
}
.governance-main-inner li p {
    font-size: 16px;
    line-height: 26px;
    padding-top: 6px;
}
.scroll-sec {
    padding: 0px;
    max-height: 350px;
    overflow-y: scroll;
}


.scroll-sec::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scroll-sec::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.scroll-sec::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #555555;
}
.technology-main-inner-graphic {
    margin-bottom: 30px;
}
.col-md-6.col-sm-12.scroll-sec {
    margin-bottom: 30px;
}
.logo-main-inner ul li {
    display: inline-block;
    padding: 10px;
}
.certificate img {
    /* min-width: 530px; */
    width: 500px;
}