.left-sec {
    width: 15%;
    float: left;
    text-align: center;
}
.right-sec.right-menu {
    float: right;
    width: 85%;
}
/*mobile*/
@media only screen and (max-width: 500px)
{   a.chitranshlogo.cd {
        width: 150px!important;
        margin-top: 10px;
    }
     .left-sec {
       width: 100%;
    }
    .right-sec.right-menu {
        width: 100%;
    }
    #banner-section {
        background-size: contain !important;
    }
    .navbar-light .navbar-nav .nav-link {
        text-align: left;
    }
    .topbar-left img {
        width: 122px!important;
        float: none!important;
        text-align: center!important;
    }
    .right-menu {
        background: #1f2d5b;
        padding: 0px;
        margin-top: 13px;
        margin-bottom: 0px;
    }
    .navbar {
        padding: 10px 10px!important;
    }
    .navbar-light .navbar-toggler-icon {
        background-image: url(../images/menu.png)!important;
    }
    .navbar-light .navbar-toggler {
        color: rgba(0,0,0,.5);
        border-color: #fff!important;
    }
    .mr-auto, .mx-auto {
        margin-right: auto!important;
        border: 1px solid #fff;
        margin-top: 10px;
    }
    .navbar-nav {
        border-bottom: 1px solid #fff;
    }
    .dropdown.nav-item {
        border-bottom: 1px solid #fff;
    }
    .navbar-light .navbar-nav .nav-link {
        color: #fff!important;
    }
    .dropdown-menu.show {
        margin-right: 10px;
        padding: 0px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    a.dropdown-item {
        border-bottom: 2px solid #1f2d5b;
    }
    .dropdown-menu.show {
        background: transparent;
        border: 1px solid #fff;
    }
    a.dropdown-item {
        border-bottom: 1px solid #fff;
    }
    .person-detail {
        text-align: center;
        margin-top: 10px;
    }
    .slidersection .first-slider.carousel-item{
        padding: 0px 2px;
        height: 280px;
    }
    .slidersection .second-slider.carousel-item{
        padding: 0px 2px;
        height: 280px;
    }
    .slidersection .third-slider.carousel-item{
        padding: 0px 2px;
        height: 280px;
    }
    .man-content {
        width: 100%!important;
    }
    .tab-content {
        padding: 10px 20px!important;
    }
    section.post-provide-sec {
        padding: 20px 10px!important;
    }
    a.chitranshlogo.cm {
        display: none;
    }

}

/*ipad*/
@media (min-width: 768px) and (max-width: 991px)
{  a.chitranshlogo.cm {
    display: none;
}
    .top-btn {
        border-right: 2px solid #ddd;
        text-align: left;
    }
    a.chitranshlogo.cd {
        margin-left: 20px;
    }
    .left-sec {
        width: 100%;
    }
    .right-sec.right-menu {
        width: 100%;
    }
    .navbar-light .navbar-nav .nav-link {
        text-align: left;
    }
    .topbar-left img {
        width: 122px!important;
        float: none!important;
        text-align: center!important;
    }
    .right-menu {
        background: #1f2d5b;
        padding: 0px;
        margin-top: 13px;
        margin-bottom: 0px;
    }
    .navbar {
        padding: 10px 10px!important;
    }
    .navbar-light .navbar-toggler-icon {
        background-image: url(../images/menu.png)!important;
    }
    .navbar-light .navbar-toggler {
        color: rgba(0,0,0,.5);
        border-color: #fff!important;
    }
    .mr-auto, .mx-auto {
        margin-right: auto!important;
        border: 1px solid #fff;
        margin-top: 10px;
    }
    .navbar-nav {
        border-bottom: 1px solid #fff;
    }
    .dropdown.nav-item {
        border-bottom: 1px solid #fff;
    }
    .navbar-light .navbar-nav .nav-link {
        color: #fff!important;
    }
    .dropdown-menu.show {
        margin-right: 10px;
        padding: 0px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    a.dropdown-item {
        border-bottom: 2px solid #1f2d5b;
    }
    .dropdown-menu.show {
        background: transparent;
        border: 1px solid #fff;
    }
    a.dropdown-item {
        border-bottom: 1px solid #fff;
    }
    header.header-01.re-none .container {
        width: 100%;
        min-width: 100%;
    }
}

@media only screen and (max-width: 600px){
  
}

@media (min-width: 480px) and (max-width: 600px)
{
    .carousel-caption h3 {
        font-size: 30px;
        margin-bottom: 0px;
        color: #fff;
    }
    .slidersection .first-slider.carousel-item{
        height: 480px;
    }
    .slidersection .second-slider.carousel-item{
        height: 480px;
    }
    .slidersection .third-slider.carousel-item{
        height: 480px;
    }
    a.chitranshlogo.cm {
        display: none;
    }
}

/*Computer*/
@media (min-width: 1300px)
{
    a.chitranshlogo.cd {
        display: none;
    }
}
@media (max-width: 767px){
    header {
        background: transparent;
        /* background: #222222; */
    }
    a.chitranshlogo.cd {
        width: 150px!important;
        margin-top: 10px;
    }
     .left-sec {
       width: 100%;
    }
    .right-sec.right-menu {
        width: 100%;
    }
    #banner-section {
        background-size: contain !important;
    }
    .navbar-light .navbar-nav .nav-link {
        text-align: left;
    }
    .topbar-left img {
        width: 122px!important;
        float: none!important;
        text-align: center!important;
    }
    .right-menu {
        background: #1f2d5b;
        padding: 0px;
        margin-top: 13px;
        margin-bottom: 0px;
    }
    .navbar {
        padding: 10px 10px!important;
    }
    .navbar-light .navbar-toggler-icon {
        background-image: url(../images/menu.png)!important;
    }
    .navbar-light .navbar-toggler {
        color: rgba(0,0,0,.5);
        border-color: #fff!important;
    }
    .mr-auto, .mx-auto {
        margin-right: auto!important;
        border: 1px solid #fff;
        margin-top: 10px;
    }
    .navbar-nav {
        border-bottom: 1px solid #fff;
    }
    .dropdown.nav-item {
        border-bottom: 1px solid #fff;
    }
    .navbar-light .navbar-nav .nav-link {
        color: #fff!important;
    }
    .dropdown-menu.show {
        margin-right: 10px;
        padding: 0px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    a.dropdown-item {
        border-bottom: 2px solid #1f2d5b;
    }
    .dropdown-menu.show {
        background: transparent;
        border: 1px solid #fff;
    }
    a.dropdown-item {
        border-bottom: 1px solid #fff;
    }
    .person-detail {
        text-align: center;
        margin-top: 10px;
    }
    .slidersection .first-slider.carousel-item{
        padding: 0px 2px;
        height: 280px;
    }
    .slidersection .second-slider.carousel-item{
        padding: 0px 2px;
        height: 280px;
    }
    .slidersection .third-slider.carousel-item{
        padding: 0px 2px;
        height: 280px;
    }
    .man-content {
        width: 100%!important;
    }
    .tab-content {
        padding: 10px 20px!important;
    }
    section.post-provide-sec {
        padding: 20px 10px!important;
    }
    a.chitranshlogo.cm {
        display: none;
    }
    .container, .container-sm {
        
        max-width: 100%;
    }
}
/*ipad pro*/
@media (min-width: 991px) and (max-width: 1200px){
    a.chitranshlogo.cm {
        display: block!important;
    }
    .left-sec {
        width: 10%;
    }
    .right-sec.right-menu {
        width: 90%;
    }
    .navbar-light .navbar-nav .nav-link {
        
        font-size: 12px;
    }
    .top-btn button {
        padding: 5px 20px;
       
        font-size: 12px;
        
    }
    a.chitranshlogo.cd {
    display: none;
}
}

/*mobile*/
@media only screen and (max-width: 479px)
{
    .certificate img {
        /* min-width: 530px; */
        width: 100%!important;
    }


}
.gallery-tab-inner .nav-item a {
    border: 1px solid #1f2d5b;
    color: #1f2d5b!important;
    border-radius: 0px;
    margin-bottom: 8px;
    padding: 8px 10px;
    text-align: left;
}

#gallery-tab-sec .gallery-tab-inner a.active{
    border: 1px solid #1f2d5b;
    color: #fff!important;
    background-color: #1f2d5b!important;
}
#gallery-tab-sec .gallery-tab-inner a:hover{
    border: 1px solid #1f2d5b;
    color: #fff!important;
    background-color: #1f2d5b!important;
}
.gallery-tab-inner a i {
    margin-right: 10px;
    transform: rotate(-90deg);
}
