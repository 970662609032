img {
   
    width: 100%;
}
section.project-list {
    padding: 70px 0px;
    
}
.project-content {
    padding: 15px 15px;
    text-align: center;
    background-color: white;
}
.project-content h2 {
    font-size: 25px;
}
.flat-link {
    left: 0;
    margin-top: -15px;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    z-index: 2;
}
.project-image {
    height: 250px;
}
.project-inner {
    margin-bottom: 50px;
}
.flat-link > a {
    border: 1px solid #fff;
    color: #fff;
    font-family: "montserrat";
    font-size: 14px;
    font-weight: normal;
    padding: 9px 12px 10px;
    text-transform: uppercase;
}
.flat-link > a:hover {
    background: #1f2d5b none repeat scroll 0 0;
    border: 1px solid #1f2d5b;
    color: #fff;
}
.project-image:before {
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 100%;
    z-index: 1;
}
.project-image {
    position: relative;
    overflow: hidden;
}
.project-inner:hover .project-image:before {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
}
.project-image > a {
    display: block;
}
.project-inner:hover .project-image > a > img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}
.project-inner:hover .flat-link {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
}
.project-image > a > img {
    width: 100%;
    -webkit-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
}
.logo-filter .flex-column.nav.nav-pills .nav-item {
    display: inline-block!important;
    
}
.logo-filter .nav {
    display: inline-block!important;
}

.logo-filter .nav-item a {
    border: 1px solid #1f2d5b;
    margin-right: 5px;
    color: #1f2d5b!important;
    font-size: 14px;
    padding: 14px 15px;
}

.logo-filter .nav-link.active{
    color: #fff!important;
    background-color: #1f2d5b;
}
.logoitem {
    padding: 20px;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    transition: all .2s ease;
    border-radius: 10px;
    box-shadow: 0 10px 15px 0px rgba(64, 64, 64, 0.1);
    background: #fff;
}
.logo-img.tab-content {
    padding: 30px 10px;
}
ul.logo-list-item li {
    display: inline-block;
    width: 15.5%;
    border: 1px solid #ddd;
    margin-right: 10px;
    padding: 10px;
    margin-bottom: 20px;
}
ul.logo-list-item {
    width: 100%;
}
.logo-filter .flex-column.nav.nav-pills .nav-item {
   
    margin-bottom: 15px;
}
/*mobile*/
@media only screen and (max-width: 600px)
{ 
    ul.logo-list-item li {
        
        width: 45.5%;
       
    }
    .logo-filter .nav-item a {
        border: 1px solid #1f2d5b;
        margin-right: 5px;
        color: #1f2d5b!important;
        font-size: 10px;
        padding: 13px 8px;
        margin-bottom: 10px;
    }

}

/*ipad*/
@media (min-width: 768px) and (max-width: 991px)
{
    ul.logo-list-item li {
        
        width: 30.5%;
       
    }
}