@import url(https://fonts.googleapis.com/css?family=Oswald:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,400i,500,600,600i,700,800,900);
/* @import url(../css/font-awesome.min.css);
@import url(../css/ionicons.min.css);
@import url(../css/mega-menu/mega_menu.css);
@import url(../css/owl-carousel/owl.carousel.css);
@import url(../css/magnific-popup.css);
@import url(../css/animate.css);
@import url(../css/shortcodes.css);
@import url(../css/responsive.css); */
body {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: #666;
    letter-spacing: .5px
}

a,
input {
    -webkit-transition: all .5s ease-out 0s;
    -moz-transition: all .5s ease-out 0s;
    -ms-transition: all .5s ease-out 0s;
    -o-transition: all .5s ease-out 0s;
    transition: all .5s ease-out 0s;
    color: #222;
    outline: 0!important
}

a:focus {
    text-decoration: none!important
}

a:focus,
a:hover {
    color: #003082;
    text-decoration: none!important
}

.button {
    -webkit-transition: all .5s ease-out 0s;
    -moz-transition: all .5s ease-out 0s;
    -ms-transition: all .5s ease-out 0s;
    -o-transition: all .5s ease-out 0s;
    transition: all .5s ease-out 0s;
    color: #fff;
    outline: 0!important
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: 400;
    color: #222;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Oswald, sans-serif;
    text-transform: uppercase
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit
}

h1 {
    font-size: 40px;
    line-height: 55px
}

h2 {
    font-size: 36px;
    line-height: 50px
}

h3 {
    font-size: 32px;
    line-height: 45px
}

h4 {
    font-size: 28px;
    line-height: 40px
}

h5 {
    font-size: 24px;
    line-height: 35px
}

h6 {
    font-size: 20px;
    line-height: 26px
}

hr {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #e9e9e9;
    border-top: 0
}

ol,
ul {
    padding: 0;
    margin: 0
}

li {
    list-style: none!important
}

label {
    font-weight: 400;
    color: #aaa
}

.label {
    color: #fff!important;
    font-size: 9px!important
}

::-moz-selection {
    background: #003082;
    color: #fff;
    text-shadow: none
}

::-moz-selection {
    background: #003082;
    color: #fff;
    text-shadow: none
}

::selection {
    background: #003082;
    color: #fff;
    text-shadow: none
}

.big-text {
    font-size: 60px;
    line-height: 80px
}

.hps-hide {
    overflow: hidden
}

.nice-font {
    font-family: 'PT Serif', serif;
    font-style: italic
}

.wd-full {
    width: 100%!important
}

ul.one-line {
    display: inline-block
}

ul.one-line li {
    float: left;
    margin: 0 4px
}

.hps-pos-r {
    position: relative
}

.hps-pos-a {
    position: absolute
}

iframe {
    border: inherit
}

#back-to-top {
    display: inline!important
}

#back-to-top .top {
    z-index: 999;
    position: fixed;
    margin: 0;
    color: #fff;
    background: #333;
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 999;
    font-size: 26px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    -webkit-border-radius: 90px;
    -moz-border-radius: 90px;
    border-radius: 90px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

#back-to-top .top:hover {
    background: #003082;
    color: #fff;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1)
}

input {
    background: 0 0;
    border: 1px solid #d2d2d2;
    color: #666;
    height: 45px;
    box-shadow: none;
    padding-left: 10px;
    transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    font-size: 14px;
    color: #626262;
    width: 100%
}

select {
    width: 100%;
    background: 0 0;
    border: 1px solid #e0e0e0;
    color: #666;
    height: 45px;
    padding-left: 10px;
    box-shadow: none;
    transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    font-size: 14px;
    color: #626262
}

option {
    background: #fff;
    border: 0 solid #626262;
    padding-left: 10px;
    font-size: 14px
}

input:focus {
    background: 0 0;
    border: 1px solid #003082
}

textarea {
    background: 0 0;
    resize: vertical;
    border: 1px solid #e0e0e0;
    color: #666!important;
    padding: 10px;
    width: 100%;
    transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    font-size: 14px
}

textarea:focus {
    background: 0 0;
    border: 1px solid #003082;
    outline: 0!important
}

.form-control {
    background-color: transparent;
    border: 1px solid #2f2f2f;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none
}

.form-control:focus {
    box-shadow: none;
    border-color: #2f2f2f
}

input::-webkit-input-placeholder {
    color: #666
}

input::-moz-placeholder {
    color: #666
}

input:-moz-placeholder {
    color: #666
}

input:-ms-input-placeholder {
    color: #666
}

input:focus {
    outline: 0
}

.jarallax {
    position: relative;
    z-index: 0
}

.jarallax>.jarallax-img {
    position: absolute;
    object-fit: cover;
    font-family: 'object-fit:cover; ';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

.owl-carousel .owl-item img {
    width: auto
}

.owl-carousel .owl-item img.img-full {
    width: 100%!important
}

.owl-carousel.arrow-1 {
    overflow: hidden
}

.owl-carousel.arrow-1 .owl-nav {
    display: block;
    position: absolute;
    text-indent: inherit;
    top: 50%!important;
    margin-top: -20px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 0;
    width: 100%;
    cursor: pointer
}

.owl-carousel.arrow-1 .owl-nav .owl-prev {
    display: block;
    position: absolute;
    text-indent: inherit;
    left: -44px;
    width: auto;
    cursor: pointer;
    -webkit-transition: opacity .3s ease 0s, left .3s ease 0s;
    -moz-transition: opacity .3s ease 0s, left .3s ease 0s;
    -ms-transition: opacity .3s ease 0s, left .3s ease 0s;
    -o-transition: opacity .3s ease 0s, left .3s ease 0s;
    transition: opacity .3s ease 0s, left .3s ease 0s
}

.owl-carousel.arrow-1 .owl-nav .owl-next {
    display: block;
    position: absolute;
    text-indent: inherit;
    right: -44px;
    width: auto;
    cursor: pointer;
    -webkit-transition: opacity .3s ease 0s, right .3s ease 0s;
    -moz-transition: opacity .3s ease 0s, right .3s ease 0s;
    -ms-transition: opacity .3s ease 0s, right .3s ease 0s;
    -o-transition: opacity .3s ease 0s, right .3s ease 0s;
    transition: opacity .3s ease 0s, right .3s ease 0s
}

.owl-carousel.arrow-1 .owl-nav i {
    font-weight: 400;
    font-size: 24px;
    color: #fff;
    background: rgba(34, 34, 34, .7);
    padding: 8px 20px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.owl-carousel.arrow-1 .owl-nav i:hover {
    background: #003082
}

.owl-carousel.arrow-1 .owl-nav .owl-prev {
    left: 0;
    display: inline-grid
}

.owl-carousel.arrow-1 .owl-nav .owl-next {
    right: 0;
    display: inline-grid
}

.owl-carousel.arrow-1 .owl-stage-outer {
    overflow: hidden
}

.subscribe .form-control {
    width: 300px;
    height: 46px;
    border: 1px solid #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: inherit;
    color: #fff
}

.subscribe label {
    margin-right: 10px;
    color: #fff;
    font-size: 24px
}

.subscribe label i {
    color: #003082;
    background: #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    font-size: 32px;
    line-height: 60px;
    width: 60px;
    display: inline-block;
    height: 60px;
    vertical-align: middle;
    -webkit-transform: rotate(340deg);
    -moz-transform: rotate(340deg);
    -o-transform: rotate(340deg);
    writing-mode: lr-tb;
    margin-right: 15px
}

.subscribe .button {
    margin-left: -17px;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    box-shadow: none;
    cursor: pointer
}

.subscribe input::-webkit-input-placeholder {
    color: #fff
}

.subscribe input::-moz-placeholder {
    color: #fff
}

.subscribe input:-moz-placeholder {
    color: #fff
}

.subscribe input:-ms-input-placeholder {
    color: #fff
}

.subscribe input:focus {
    outline: 0
}

.action-box1 {
    padding: 40px 0;
    position: relative;
    display: block
}

.action-box1:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 40px solid #003082;
    border-left: 30px solid transparent;
    position: absolute;
    z-index: 9;
    bottom: -40px;
    left: 20%
}

.rev_slider .slotholder:after {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none
}

.section-heading span {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: #003082;
    line-height: 200%;
    margin-bottom: 10px
}

.section-heading h2 {
    position: relative;
    font-size: 42px;
    font-weight: 500;
    color: #111;
    line-height: 130%;
    margin-bottom: 10px
}

.section-heading-line {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background: #003082;
    border-radius: 25%;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 15px;
    margin-bottom: 5px
}

.section-heading p {
    font-size: 15px;
    font-weight: 400;
    color: #aaa;
    line-height: 160%;
    padding-top: 15px;
    margin-bottom: 0
}

.section-block-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: 80px 0 80px 0
}

.mt-40 {
    margin-top: 40px
}

.plane-box {
    margin: 0 auto;
    text-align: center
}

.plane-box-item {
    position: relative;
    display: inline-block;
    width: 33.33%;
    margin-right: -3px;
    padding-right: 40px;
    padding-left: 40px
}

.plane-box-decor {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 29px;
    height: 29px
}

.plane-box-decor::before {
    background-color: #003082;
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    width: 9px;
    height: 9px;
    content: '';
    border-radius: 50%;
    box-shadow: 0 0 0 5px rgba(0, 48, 130, .2)
}

.plane-box-decor::after {
    background-color: #003082;
    display: block;
    width: 1px;
    height: 15px;
    margin: 19px auto 0;
    content: ''
}

.plane-box-text h3 {
    color: #bbb;
    font-size: 52px;
    font: italic 60px;
    margin: 15px 0;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    font-family: initial
}

.plane-box-text h4 {
    color: #222;
    font-size: 28px;
    font-weight: 600;
    margin: 15px 0;
    letter-spacing: .6px
}

.plane-box-text p {
    color: #585858;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px
}

.plane-box-item::after {
    position: absolute;
    top: 14px;
    left: 50%;
    display: block;
    width: 100%;
    height: 1px;
    content: '';
    background-color: #003082;
    opacity: .2
}

.section-heading {
    margin-bottom: 10px
}

.center-holder {
    text-align: center!important
}

.plane-box-text {
    position: relative;
    display: inline-block
}

.plane-box-item:last-child:after {
    content: none
}

.cornered-heading,
.with_corners {
    position: relative
}

.with_shadow {
    box-shadow: 0 4px 16px 0 rgba(51, 51, 51, .1)
}

.bg_teaser {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: relative;
    color: #fff;
    -webkit-transition: all .4s linear 0s;
    transition: all .4s linear 0s;
    overflow: hidden
}

.bg_teaser .teaser_content {
    margin: 0;
    padding: 45px 35px;
    z-index: 2;
    position: relative;
    min-height: 500px;
}

.teaser {
    position: relative;
    overflow: visible
}

.text-center {
    text-align: center
}

.grey {
    color: #333;
    border-color: #333
}

.size_big {
    font-size: 60px
}

.teaser_icon {
    display: inline-block;
    line-height: 1;
    -webkit-transition: all .2s ease-in-out 0s;
    transition: all .2s ease-in-out 0s
}

.teaser h4 {
    margin-bottom: 18px
}

.teaser {
    position: relative;
    overflow: visible
}

.teaser .media-body {
    width: auto
}

.teaser[class*="_bg_color"],
.teaser[class*=with_] {
    padding: 36px 55px
}

@media (max-width:500px) {
    .teaser[class*="_bg_color"],
    .teaser[class*=with_] {
        padding: 16px 20px
    }
}

.teaser .with_background,
.teaser [class*="_bg_color"] {
    padding: 17px;
    margin-bottom: 2px;
    text-align: center
}

.teaser .with_background+h4,
.teaser [class*="_bg_color"]+h4 {
    margin-top: 24px
}

.teaser .border_icon {
    padding: .51em;
    min-width: 1.8em;
    border-width: 3px;
    border-style: solid
}

.teaser i {
    min-width: 1em;
    display: inline-block
}

.teaser h2 {
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 33px;
    position: relative;
    padding-bottom: 10px
}

.teaser h2:after {
    content: '';
    position: absolute;
    width: 50px;
    margin-left: -25px;
    height: 1px;
    background-color: rgba(204, 204, 204, .2);
    bottom: -12px;
    left: 50%
}

.teaser h3 {
    margin-bottom: 16px;
    font-size: 24px
}

.teaser h4 {
    margin-bottom: 18px
}

.teaser .media-left {
    padding-right: 20px
}

@media (max-width:500px) {
    .teaser .media-left {
        padding-right: 20px
    }
}

.teaser .media-body h3 {
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase
}

.teaser .media-body h4 {
    margin-bottom: 6px
}

@media (max-width:500px) {
    .teaser h2,
    .teaser h3,
    .teaser h4 {
        font-size: 1.4em
    }
    .teaser h2 span,
    .teaser h3 span,
    .teaser h4 span {
        font-size: 1.2em
    }
}

.teaser [class*=count] {
    display: inline-block;
    font-size: 40px;
    font-weight: 700;
    margin: 10px 0 0
}

.teaser [class*=count]+p {
    margin: 17px 0 21px
}

.teaser .theme_button {
    margin: 10px 0 0
}

.section_padding_bottom_0 .teaser {
    margin-bottom: 0
}

.teaser_icon {
    display: inline-block;
    line-height: 1;
    -webkit-transition: all .2s ease-in-out 0s;
    transition: all .2s ease-in-out 0s
}

.teaser_icon+p {
    margin-top: 15px
}

.teaser:hover .teaser_icon {
    -webkit-animation: scaleDisappear .5s;
    animation: scaleDisappear .5s
}

.bg_teaser>img {
    display: none;
    visibility: hidden
}

.bg_teaser .bg_overlay {
    position: absolute;
    width: 100%;
    height: 1000px;
    background-color: #fff;
    -webkit-transition: all .4s linear 0s;
    transition: all .4s linear 0s
}

.cornered-heading>.bg_overlay,
.with_corners>.bg_overlay {
    z-index: 1
}

.bg_teaser.light_teaser.hover .bg_overlay,
.bg_teaser.light_teaser:hover .bg_overlay {
    background-color: rgba(245, 245, 245, .9);
    -webkit-transition: all 50ms linear 0s;
    transition: all 50ms linear 0s
}

.teaser {
    position: relative;
    overflow: visible
}

.darken-font-color {
    color: rgba(48, 48, 48, .8)
}

.theme_button,
.theme_buttons a {
    font-size: 16px;
    font-weight: 700;
    padding: 14px 20px 14px;
    margin-bottom: 4px;
    line-height: 1;
    display: inline-block;
    min-width: 8.5em;
    text-align: center;
    color: #fff!important;
    border: none;
    background-color: #1f2d5b;
    border-radius: 0;
    position: relative;
    -webkit-transition: all .4s linear 0s;
    transition: all .4s linear 0s
}

.bottommargin_25 {
    margin-bottom: 25px
}

.divider_2_40 {
    height: 2px;
    width: 40px;
    margin-left: auto;
    margin-right: auto
}

hr {
    border: none;
    height: 1px;
    background-color: #333
}

.teaser h4 {
    margin-bottom: 18px
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6 {
    color: #333
}

.topmargin_25 {
    margin-top: 30px
}

.text-uppercase {
    text-transform: uppercase
}

.cornered-heading:before,
.with_corners:before {
    border-width: 2px 0 0 2px
}

.with_corners:after,
.with_corners:before {
    border-color: #333;
    -webkit-transition: all .2s ease-in-out 0s;
    transition: all .2s ease-in-out 0s
}

.cornered-heading:after,
.cornered-heading:before,
.with_corners:after,
.with_corners:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 2
}

.with_corners:before {
    top: 20px;
    left: 20px;
    border-width: 2px 0 0 2px
}

.cornered-heading:after,
.cornered-heading:before,
.with_corners:after,
.with_corners:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 2
}

.with_corners:after {
    bottom: 20px;
    right: 20px;
    border-width: 0 2px 2px 0
}

.teaser_icon img {
    width: 60px
}

h4.text-uppercase.topmargin_25 {
    font-weight: 700;
    font-size: 22px;
    letter-spacing: .8px
}

.blur {
    -webkit-filter: blur(9px);
    -moz-filter: blur(9px);
    -o-filter: blur(9px);
    -ms-filter: blur(9px)
}

.main-bg {
    background: url(https://premiumlayers.net/demo/html/seowave/images/slider-pattern.png)
}

.bg-2 {
    background: url(../images/der.jpg);
    position: relative;
    background-size: cover;
    background-position: center;
    background-attachment: fixed
}

.bg-2:before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    background-color: rgba(255, 255, 255, .8)
}

ul.listing-awesom.hps-mtb-30 {
    text-transform: capitalize;
    font-size: 16px;
    line-height: 35px
}

ul.listing-awesom.hps-mtb-30 i {
    margin-right: 10px;
    color: #0056b3
}

a.popup-youtube {
    position: absolute;
    top: 41%;
    color: #fff;
    font-size: 60px;
    left: 0;
    right: 0
}

.list-content li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 25px
}

.list-content li:before {
    content: "\f05d";
    font-family: FontAwesome;
    display: inline-block;
    width: 1.3em;
    position: absolute;
    left: 0;
    color: #003082
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 29px
}

.rev_slider .slotholder:after {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    background: rgba(255, 255, 255, .67);  z-index:999; 
}
li.request a {
    color: #fff!important;
    text-transform: uppercase;
    font-weight: 500;
    background: #f96e42;
    padding: 8px 15px;
    border-radius: 5px
}