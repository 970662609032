.col-md-6.post-img img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
section.post-provide-sec:before {
    background: #0c2a48;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    z-index: 0;
    mix-blend-mode: multiply;
}
section.post-provide-sec {
    background-image: url(https://medwinmechanical.com/wp-content/uploads/2018/05/pexels-photo-290595.jpeg);
    /* text-align: center; */
    background-size: cover !important;
    -webkit-background-size: cover !important;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    background-attachment: fixed !important;
    position: relative;
    padding: 50px 0;
}
section.post-provide-sec {
    margin-bottom: 50px;
}
section.post-provide-sec h3 {
    color: #fff;
    z-index: 99999;
    text-align: center;
    justify-content: center;
}
.row.post-provide-title {
    text-align: center;
    float: none;
    justify-content: center;
    margin-bottom: 20px;
}
.feature-checked a i {
    padding-right: 6px;
}
.feature-checked a {
    /* color: #cd5219; */
    /* color: #cd5219 !important; */
    z-index: 9999;
    font-size: 15px;
    text-align: left;
    float: left;
    margin-bottom: 20px;
}
section.normal-content-sec {
    text-align: left;
}
ul.inner-list {
    padding-left: 30px;
}