
.project-table tr th {
    padding:18px 10px;
    font-size: 18px;
    text-transform: uppercase;
    background-color: #1f2d5b;
    color: #fff;
    text-align: center;
    border: 1px solid #1f2d5b;
    font-size: 16px;
}
.project-list {
    margin: 20px 0px;
}
.project-list-inner h3 {
    font-size: 24px;
}
.project-list-inner {
    margin: 30px 0px;
}
.project-table table tr td {
    text-align: left;
}
.project-table tr td {
    font-size: 14px;
}

.project-table table tr td {
    text-align: left;
    /* text-align: center; */
    padding: 10px 10px;
    font-size: 16px;
    /* border: 2px solid #cfcfcf; */
    /* color: #787875; */
    vertical-align: middle;
}
