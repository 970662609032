.topbar {
    padding: 12px 0;
    background: #1f2d5b;
    font-size: 12px;
    font-weight: 500
}

.topbar li {
    position: relative
}

.topbar li,
.topbar li a {
    line-height: initial;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    color: #fff
}

.topbar .topbar-right li a:hover {
    color: #003082;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out
}

.topbar .list-inline-item:not(:last-child) {
    border-right: 1px solid #fff;
    padding-right: 15px;
    margin-right: 10px
}

.topbar .list-inline-item a:hover {
    color: #003082
}

.topbar .list-inline-item i {
    padding-right: 8px
}

.topbar .topbar-right .hps-left .list-inline-item {
    position: relative;
    border: none
}

.topbar .topbar-right .hps-left .list-inline-item:first-child:before {
    position: absolute;
    content: "/";
    right: 0;
    top: 0
}
.navbar-nav a {
    font-size: 15px;
    z-index: 9;
    line-height: 36px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .8px;
    color: #1f2d5b !important;
}
ol, ul {
    padding: 0;
    margin: 0;
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,.5);
    margin: 0;
    padding: 0 20px;
    display: inline-block;
    float: none;
    width: 100%;
    color: #fff;
    /* font-size: .8125em; */
    line-height: 90px;
    position: relative;
}
.navbar {
    
    padding: 0px 0px!important;
}